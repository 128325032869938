<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this promocode ?</span
            >
          </v-flex>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 14px;
              "
              >{{ couponData.code }}</span
            >
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn tile block depressed dark color="red" @click="deleteProduct">
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="deleteDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center style="background-color: white">
      <v-flex px-2 xs12 sm12 md11 lg11 xl11 text-left>
        <v-layout pt-8 wrap justify-center>
          <v-flex text-left xs6 sm8 md10>
            <span
              style="color: #000000; font-family: poppinsbold; font-size: 22px"
              >Edit Promocode</span
            >
          </v-flex>
          <v-flex xs6 sm4 md2>
            <v-btn
              tile
              block
              small
              outlined
              color="warning"
              style="text-transform: none"
              @click="deleteDialogue = true"
            >
              <span
                style="
                  color: #000;
                  font-family: poppinsregular;
                  font-size: 14px;
                "
              >
                Delete
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 pt-8>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Promocode</span
            >
            <v-text-field
              v-model="couponData.code"
              placeholder="Promo Code"
              required
              outlined
              dense
            ></v-text-field>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Description
            </span>
            <v-text-field
              v-model="couponData.description"
              placeholder="Description"
              outlined
              dense
            ></v-text-field>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Discount
            </span>
            <v-text-field
              v-model="couponData.discount"
              placeholder="Discount Percentage"
              dense
              required
              outlined
            ></v-text-field>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Maximum Amount To Be Applied
            </span>
            <v-text-field
              v-model="couponData.maxDiscountAmount"
              placeholder="Maximum Amount To Be Applied"
              dense
              required
              outlined
            ></v-text-field>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Select From Date
            </span>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="couponData.startingDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="couponData.startingDate"
                  placeholder="Select From Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="couponData.startingDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(couponData.startingDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Select To Date
            </span>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="couponData.endingDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="couponData.endingDate"
                  placeholder="Select To Date"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="couponData.endingDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu1.save(couponData.endingDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Select Starting Time
            </span>
            <v-menu
              ref="menu3"
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="couponData.startingTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="couponData.startingTime"
                  placeholder="Starting Time"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu3"
                v-model="couponData.startingTime"
                full-width
                @click:minute="$refs.menu3.save(couponData.startingTime)"
              ></v-time-picker>
            </v-menu>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Select Ending Time
            </span>
            <v-menu
              ref="menu4"
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="couponData.endingTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="couponData.endingTime"
                  placeholder="Ending Time"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu4"
                v-model="couponData.endingTime"
                full-width
                @click:minute="$refs.menu4.save(couponData.endingTime)"
              ></v-time-picker>
            </v-menu>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Maximum Order To Be Applied
            </span>
            <v-text-field
              v-model="couponData.maximumOderTobeApplied"
              placeholder="Maximum Order To Be Applied "
              dense
              required
              type="number"
              outlined
            ></v-text-field>
            <span
              class="pl-3"
              style="
                color: #000000;
                font-family: opensansbold;
                font-size: 15px;
                letter-spacing: 0px;
                text-align: left;
              "
            >
              Limit Per Customer
            </span>
            <v-text-field
              v-model="couponData.limitPerCustomer"
              placeholder="Limit Per Customer "
              dense
              required
              type="number"
              outlined
            ></v-text-field>
            <v-layout wrap justify-end>
              <v-flex xs12 md6 lg6 pt-4 pb-4 pa-2 text-right>
                <v-btn
                  tile
                  block
                  dark
                  color="black"
                  depressed
                  class="text-capitalize mb-3"
                  @click.stop="promoDialog = false"
                  :ripple="false"
                  >Close</v-btn
                >
              </v-flex>
              <v-flex xs12 md6 lg6 pt-4 pb-4 text-right pa-2>
                <v-btn
                  tile
                  block
                  dark
                  color="#30B868"
                  depressed
                  class="text-capitalize mb-3"
                  @click.prevent="editCode()"
                  :ripple="false"
                  >Submit</v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "id"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
      promoDialog: false,
      ItemProduct: "",
      menu1: false,
      menu: false,
      menu3: false,
      menu4: false,
      couponData: {
        discount: null,
        code: null,
        description: "",
        startingDate: new Date().toISOString().substr(0, 10),
        endingDate: new Date().toISOString().substr(0, 10),
        startingTime: "",
        endingTime: "",
        maxDiscountAmount: "",
        maximumOderTobeApplied: "",
        limitPerCustomer: "",
      },
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/promoCode/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.couponData = response.data.data;
          }
        })
        .catch((err) => {
          // this.ServerError = true;
          console.log(err);
        });
    },
    editCode() {
      this.couponData.id = this.id;
      axios({
        url: "/promoCode/edit/seller",
        method: "POST",
        data: this.couponData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Inventory");
            this.msg = "Edited Sucessfully";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteProduct() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/promoCode/delete",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$router.push("/Inventory");
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>

<style>
div.vertical-line {
  width: 1px; /* Line width */
  background-color: #8d8d8d; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left; /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */
}
.statusKey.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.colorselector.v-sheet.v-card {
  border-radius: 35px;
}
</style>